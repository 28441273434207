import { getToken, initVeriffKyc } from "api/kyc-veriff.js";
import { Veriff } from "@veriff/js-sdk";
import { createVeriffFrame } from "@veriff/incontext-sdk";
import eventBus from "plugins/event.js";

export const useKYCVeriff = () => {
     const verifflaunchWebSdk = async (user) => {
          // get if current user has initiated veriff KYC
          await getToken().then((tokenResponse) => {
               if (tokenResponse.status) {
                    // If session already found just init iframe

                    if (tokenResponse.sessionUrl) {
                         // init iframe
                         initIframe(tokenResponse.sessionUrl);
                    } else {
                         // if not create new token
                         const veriff = Veriff({
                              apiKey: tokenResponse.api_key,
                              parentId: "websdk-container",
                              onSession: function (err, response) {
                                   //create a record from the response
                                   initVeriffKyc(response.verification).then(
                                        () => {
                                             // startt verification when get the response url
                                             initIframe(
                                                  response.verification.url
                                             );
                                        }
                                   );
                              }
                         });

                         veriff.setParams({
                              person: {
                                   givenName: " ",
                                   lastName: " "
                              },
                              vendorData: user.username
                         });

                         veriff.mount({
                              submitBtnText: "Get verified"
                         });
                    }
               }
          });
     };

     const initIframe = (url) => {
          createVeriffFrame({
               url: url,
               onEvent: function (msg) {
                    switch (msg) {
                         case "STARTED":
                              break;
                         case "CANCELED":
                              eventBus.emit("close:idverification");
                              break;
                         case "FINISHED":
                              eventBus.emit("close:idverification");
                              break;
                    }
               },
               onReload: () => {
                    window.location.reload();
               }
          });
     };

     return {
          verifflaunchWebSdk
     };
};
