<script setup>
import Default from "./modules/default/index.vue";
import Basic from "./modules/auth/index.vue";
import { markRaw, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useAffiliate } from "composables/use-affiliate.js";
import { useUtm } from "composables/use-utm.js";

import { getSeoDetails as getSeoDetailsAPI } from "api/seo.js";

const layout = ref();
const route = useRoute();
const { setTokenParams } = useAffiliate();
const { setUtmParams } = useUtm();

watch(
     () => route.meta?.layout,
     async (metaLayout) => {
          try {
               if (metaLayout == "basic") {
                    layout.value = markRaw(Basic);
               } else if (metaLayout != null) {
                    layout.value = markRaw(Default);
               }
          } catch {
               if (metaLayout == "basic") {
                    layout.value = markRaw(Basic);
               } else {
                    layout.value = markRaw(Default);
               }
          }
     },
     { immediate: true }
);

// Function to fetch data
const fetchSEOData = async () => {
     getSeoDetailsAPI(route.fullPath)
          .then((response) => {
               let result = response.seo;
               if (result) {
                    // Remove existing meta tags
                    const existingMetaTags = document.querySelectorAll(
                         'meta[data-vue-meta="true"]'
                    );
                    existingMetaTags.forEach((tag) => tag.remove());

                    const keys = Object.keys(result);
                    keys.forEach((key) => {
                         const metaTag = document.createElement("meta");
                         metaTag.setAttribute("data-vue-meta", "true");
                         metaTag.setAttribute("property", key);
                         metaTag.setAttribute("content", result[key]);
                         document.head.appendChild(metaTag);
                    });

                    // Set title
                    document.title =
                         result.title ??
                         "Moozi: America's Gateway to Social Casino Thrills";
               }
          })
          .catch((error) => {
               console.error("Error fetching data:", error);
          });
};

// Fetch data when component mounts
onMounted(fetchSEOData);

watch(() => route.fullPath, fetchSEOData);

onMounted(() => {
     setTokenParams();
     setUtmParams();
});
</script>

<template>
     <component :is="layout"></component>
</template>
